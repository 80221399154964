<template>
  <transition appear>
    <div class="bg-white w-11.5/12 rounded-xl my-5 mx-auto py-1 px-5 shadow-lg">
      <div class="flex justify-between items-center">
        <div class="mt-5 flex justify-start items-center cursor-pointer">
          <span
            @click="goBack"
            class="material-icons rounded-full bg-teal text-white"
          >
            chevron_left
          </span>
          <router-link
            class="font-bold text-teal mx-2"
            :to="{ name: `us-locums` }"
          >
            Back To Dashboard
          </router-link>
        </div>
        <div>
          <select
            @change="onLocationFilter"
            class="bg-lightGrey w-80 py-1 px-2 text-grey"
          >
            <option :value="reset">Select Location</option>
            <option
              v-for="location in locations"
              :key="location.id"
              :value="location.id"
            >
              {{
                location.practice_name[0].toUpperCase() +
                location.practice_name.slice(1)
              }}
            </option>
          </select>
        </div>
      </div>

      <div class="bg-lightGrey rounded-xl w-12/12 my-5 mx-auto py-7 px-5">
        <div class="flex justify-between items-center">
          <h2 class="text-grey font-bold text-2xl">
            {{ monthString }} Sessions Invites
          </h2>
          <div class="flex gap-x-2">
            <va-button
              @click="decrementMonth"
              class="w-56"
              icon="chevron_left"
              color="#00b5b5"
              text-color="white"
            >
              Previous Month
            </va-button>
            <va-button
              @click="incrementMonth"
              class="w-56"
              icon-right="chevron_right"
              color="#00b5b5"
              text-color="white"
            >
              Next Month
            </va-button>
          </div>
        </div>
        <div class="bg-lightGrey rounded-xl min-h-cardM mt-5">
          <div class="mt-5">
            <div class="flex gap-x-2 text-sm">
              <span
                @click="allSessionsFunc"
                class="
                  tab
                  cursor-pointer
                  inline-block
                  py-1
                  px-7
                  rounded-md
                  border-4 border-teal
                "
                :class="{ buttonActive: tableState === 'all' }"
              >
                All
              </span>
              <span
                @click="pendingSessionsFunc"
                class="
                  tab
                  cursor-pointer
                  inline-block
                  py-1
                  px-7
                  rounded-md
                  border-4 border-teal
                "
                :class="{ buttonActive: tableState === 'pending' }"
              >
                Pending
              </span>
              <span
                @click="acceptedSessionsFunc"
                class="
                  tab
                  cursor-pointer
                  inline-block
                  py-1
                  px-7
                  rounded-md
                  border-4 border-teal
                "
                :class="{ buttonActive: tableState === 'accepted' }"
              >
                Accepted
              </span>
              <span
                @click="declinedSessionsFunc"
                class="
                  tab
                  cursor-pointer
                  inline-block
                  py-1
                  px-7
                  rounded-md
                  border-4 border-teal
                "
                :class="{ buttonActive: tableState === 'declined' }"
              >
                Declined
              </span>
            </div>
            <div
              :key="refreshTable"
              class="border-2 border-grey w-full px-x py-7 bg-white"
            >
              <!-- <div class="flex justify-center items-center h-64">
                        <span
                            class="material-icons flex align-center text-8xl text-grey"
                        >
                            event_busy
                        </span>
                        </div> -->
              <!-- <div
                class="w-11.5/12 my-3 rounded-xl mx-auto bg-lightGrey py-7 px-5 flex"
              >
                <div class="date-container w-full md:w-1/5">
                  <h2 class="font-bold text-grey text-xl my-5">
                    Tuesday 1st March
                  </h2>
                  <p class="font-bold text-teal my-5 mt-10">No Sessions</p>
                </div>
                <div class="w-full flex-wrap md:w-3/5 flex items-center gap-5">
                  <div class="flex my-auto items-center">
                    <span
                      class="material-icons text-teal border-2 text-center h-11 w-11 border-teal text-4xl rounded-full cursor-pointer"
                    >
                      add
                    </span>
                    <h2 class="text-grey font-bold text-xl ml-3">
                      Add Session
                    </h2>
                  </div>
                </div>
              </div> -->
              <!-- <div class="flex justify-center items-center h-64">
                        <span
                            class="material-icons flex align-center text-8xl text-grey"
                        >
                            event_busy
                        </span>
                        </div> -->
              <div
                v-for="day in daysInMonth"
                :key="day.dayNumber"
                class="
                  w-11.5/12
                  my-3
                  rounded-xl
                  mx-auto
                  bg-lightGrey
                  py-7
                  px-5
                  flex
                "
              >
                <div class="date-container w-full md:w-1/5">
                  <h2 class="font-bold text-grey text-xl my-5">
                    {{ day.dayString }}
                  </h2>
                  <p
                    class="font-bold text-teal my-5 mt-10"
                    v-if="day.sessions.length === 0"
                  >
                    No Sessions
                  </p>
                </div>
                <div
                  v-if="day.sessions?.length > 0"
                  class="w-full flex-wrap md:w-4/5 flex items-center gap-5"
                >
                  <div
                    v-for="session in day.sessions"
                    :key="session.id"
                    class="
                      session-card
                      border-2
                      flex flex-col
                      border-grey
                      rounded-xl
                      py-4
                      px-6
                    "
                  >
                    <div class="font-bold flex session-row">
                      <div>
                        <span class="text-grey"
                          >{{ format12(session.start_time) }} -
                          {{ session.end_date }}({{
                            format12(session.end_time)
                          }})</span
                        >
                      </div>
                      <div
                        v-if="session.invite_status == 1"
                        class="font-bold flex session-row pl-3"
                      >
                        <span
                          @click="openConfirmationDialog(session.invite_id)"
                          class="
                            material-icons
                            rounded-full
                            bg-teal
                            text-white
                            cursor-pointer
                          "
                        >
                          more_vert
                        </span>
                      </div>
                    </div>
                    <div class="flex flex-row gap-6">
                      <div>
                        <div class="font-bold flex session-row">
                          <div class="flex gap-x-2 w-full">
                            <span class="text-teal"> Name: </span>
                            <span class="text-grey"> {{ session.name }} </span>
                          </div>
                        </div>
                        <div class="font-bold flex session-row">
                          <div class="flex gap-x-2 w-1.2/2">
                            <span class="text-teal"> Location: </span>
                            <span class="text-grey">
                              {{ session.location }}
                            </span>
                          </div>
                        </div>
                        <div class="font-bold flex session-row">
                          <div class="flex gap-x-2 w-1/2">
                            <span class="text-teal"> Rate: </span>
                            <span class="text-grey">
                              ${{ session.rate }}/{{ session.unit }}
                            </span>
                          </div>
                        </div>
                        <div class="font-bold flex session-row">
                          <div class="flex gap-x-2 w-1/2">
                            <span class="text-teal"> Status: </span>
                            <span
                              v-if="session.invite_status == 1"
                              class="text-grey"
                            >
                              Pending
                            </span>
                            <span
                              v-if="session.invite_status == 2"
                              class="text-grey"
                            >
                              Accepted
                            </span>
                            <span
                              v-if="session.invite_status == 3"
                              class="text-grey"
                            >
                              Declined
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <base-confirmation-dialog
                      :show="showConfirmationDialog"
                      :errorIcon="true"
                      title="Are you sure you want to accept this session?"
                    >
                      <base-button @click="confirmSession()"
                        >Accept</base-button
                      >
                      <base-button :mode="'cancel'" @click="declineSession()"
                        >Decline</base-button
                      >
                      <div class="pt-4">
                        <span class="material-icons rounded-full text-blue">
                          email
                        </span>
                      </div>
                      <div>Contact HR about this session</div>
                    </base-confirmation-dialog>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
  <script>
import moment from "moment";
import useVuelidate from "@vuelidate/core";
import baseConfirmationDialog from "../../../components/ui/baseComponents/baseConfirmationDialog.vue";

export default {
  name: "locumSessions",
  components: {
    baseConfirmationDialog,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      monthToShow: new Date(),
      monthString: moment(new Date()).format("MMMM YYYY"),
      filter: "all",
      daysInMonth: [],
      allSessions: [],
      vacantSessions: [],
      refreshTable: 0,

      validate: true,
      masks: {
        weekdays: "WWW",
      },

      monthlySessions: [],
      monthlyInvites: [],
      locations: [],
      tableState: "all",
      year: null,
      month: null,
      showConfirmationDialog: false,
      sessionState: "pending",
      pendingSessions: [],
      acceptedSessions: [],
      declinedSessions: [],
      invitationId: 0,
    };
  },
  async created() {
    await this.fetchMonthlyInvites();
    this.getMonthlyInvites();
    await this.fetchLocations();

    this.getDaysInMonth();
    this.getLocations();
  },
  methods: {
    async incrementMonth() {
      this.tableState = "all";
      this.monthToShow = new Date(
        this.monthToShow.getFullYear(),
        this.monthToShow.getMonth() + 1,
        1
      );
      this.getDaysInMonth();
      this.monthString = moment(this.monthToShow).format("MMMM YYYY");

      if (Number(this.month) + 1 == 13) {
        this.month = 1;
        this.year = Number(this.year) + 1;
      } else {
        this.month = Number(this.month) + 1;
      }
      if (this.month / 10 < 1) {
        this.month = "0" + this.month;
      }
      await this.fetchMonthlyInvites();
      this.getMonthlyInvites();
      this.getDaysInMonth();
    },
    async decrementMonth() {
      this.tableState = "all";
      this.monthToShow = new Date(
        this.monthToShow.getFullYear(),
        this.monthToShow.getMonth() - 1,
        1
      );
      this.getDaysInMonth();
      this.monthString = moment(this.monthToShow).format("MMMM YYYY");

      if (Number(this.month) - 1 == 0) {
        this.month = 12;
        this.year = Number(this.year) - 1;
      } else {
        this.month = Number(this.month) - 1;
      }
      if (this.month / 10 < 1) {
        this.month = "0" + this.month;
      }
      await this.fetchMonthlyInvites();
      this.getMonthlyInvites();
      this.getDaysInMonth();
    },
    format12(time) {
      let newTime;
      let hours = time.slice(0, 2);
      let minutes = time.slice(3, 5);
      if (hours > 12) {
        newTime = hours % 12;
        newTime += `:${minutes} pm`;
      } else {
        newTime = `${hours}:${minutes} am`;
      }
      return newTime;
    },
    async fetchMonthlyInvites() {
      try {
        if (!this.year || !this.month) {
          const date = new Date().toISOString().split("-");
          this.year = date[0];
          this.month = date[1];
        }
        await this.$store.dispatch("UserLocums/fetchMonthlyInvites", {
          date: `${this.year}-${this.month}`,
        });
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    getMonthlyInvites() {
      this.monthlyInvites = [
        this.$store.getters["UserLocums/getMonthlyInvites"],
      ];
      this.monthlySessions = this.monthlyInvites[0];
      return this.$store.getters["UserLocums/getMonthlyInvites"];
    },
    getDaysInMonth() {
      var daysInMonth = moment(this.monthToShow).daysInMonth();
      var arrDays = [];
      let inviteId = null;
      let inviteStatus = null;
      for (let i = 0; i < this.monthlySessions.length; i++) {
        inviteId = this.monthlySessions[i].id;
        inviteStatus = this.monthlySessions[i].status;
        this.monthlySessions[i] = this.monthlySessions[i].session;
        if (inviteId) {
          this.monthlySessions[i].invite_id = 11;
        } else if (inviteStatus) {
          this.monthlySessions[i].invite_status = inviteStatus;
        }
      }
      console.log(this.monthlySessions, "monthly sessions now:");

      while (daysInMonth) {
        let dayString =
          moment(this.monthToShow).date(daysInMonth).format("dddd") +
          " " +
          moment(this.monthToShow).date(daysInMonth).format("Do") +
          " " +
          moment(this.monthToShow).date(daysInMonth).format("MMMM");
        let dayNumber = moment(this.monthToShow).date(daysInMonth).format("D");
        let sessions = [];
        sessions = this.monthlySessions.filter((session) => {
          return Number(session.start_date.split("-")[2]) == Number(dayNumber);
        });
        arrDays.push({ dayString, dayNumber, sessions });
        daysInMonth--;
      }
      this.daysInMonth = arrDays;
      this.daysInMonth.sort((a, b) => a.dayNumber - b.dayNumber);
      console.log("daysInMonth: ", this.daysInMonth);

      this.pendingSessions = this.daysInMonth;
      console.log("pending sessions: before" + this.pendingSessions);
      this.pendingSessions = this.daysInMonth.filter(
        (session) => session.sessions[0]?.invite_status < 2
      );
      this.acceptedSessions = this.daysInMonth;
      this.acceptedSessions = this.daysInMonth.filter(
        (session) =>
          session.sessions[0]?.invite_status < 3 &&
          session.sessions[0]?.invite_status > 1
      );
      console.log("pending sessions: " + this.pendingSessions);
      this.declinedSessions = this.daysInMonth;
      this.declinedSessions = this.daysInMonth.filter(
        (session) => session.sessions[0]?.invite_status > 2
      );

      this.allSessions = this.daysInMonth;

      return arrDays;
    },

    async fetchLocations() {
      try {
        await this.$store.dispatch("ReLocums/fetchLocations");
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },

    getLocations() {
      this.locations = this.$store.getters["ReLocums/getLocations"];
      return this.locations;
    },
    onLocationFilter(event) {
      this.fetchMonthlyFilterSessions(event.target.value);
    },

    async fetchMonthlyFilterSessions(data) {
      try {
        await this.$store.dispatch("UserLocums/fetchMonthlyFilterSessions", {
          practice: data,
        });
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
      this.getMonthlyFilterSessions();
    },

    goBack() {
      this.$router.push({ name: "us-locums" });
    },

    allSessionsFunc() {
      this.tableState = "all";
      this.daysInMonth = this.allSessions;
      this.refreshTable++;
    },

    pendingSessionsFunc() {
      this.tableState = "pending";
      this.daysInMonth = this.pendingSessions;
      this.refreshTable++;
    },

    acceptedSessionsFunc() {
      this.tableState = "accepted";
      this.daysInMonth = this.acceptedSessions;
      this.refreshTable++;
    },

    declinedSessionsFunc() {
      this.tableState = "declined";
      this.daysInMonth = this.declinedSessions;
      this.refreshTable++;
    },

    monthFormat(value) {
      if (value < 10) {
        return "0" + value;
      } else {
        return value;
      }
    },

    openConfirmationDialog(id) {
      this.showConfirmationDialog = true;
      this.invitationId = id;
    },

    async declineSession() {
      this.showConfirmationDialog = false;
      try {
        await this.$store.dispatch("UserLocums/sessonInviteAction", {
          session_invite: this.invitationId,
          action: 3,
        });
        await this.fetchMonthlyInvites();
        this.getMonthlyInvites();
        this.getDaysInMonth();
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }

      console.log(this.invitationId);
    },

    async confirmSession() {
      this.showConfirmationDialog = false;
      try {
        await this.$store.dispatch("UserLocums/sessonInviteAction", {
          session_invite: this.invitationId,
          action: 2,
        });
        await this.fetchMonthlyInvites();
        this.getMonthlyInvites();
        this.getDaysInMonth();
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
      console.log(this.invitationId);
    },
  },
};
</script>
  
  <style lang="postcss" scoped>
::-webkit-scrollbar {
  width: 0px;
}
::-webkit-scrollbar-track {
  display: none;
}
/deep/ .custom-calendar.vc-container {
  --day-border: 1px solid #b8c2cc;
  --day-border-highlight: 1px solid #b8c2cc;
  --day-width: 90px;
  --day-height: 90px;
  --weekday-bg: #f8fafc;
  --weekday-border: 1px solid #eaeaea;
  border-radius: 0;
  width: 100%;
  & .vc-header {
    background-color: #f1f5f8;
    padding: 10px 0;
  }
  & .vc-weeks {
    padding: 0;
  }
  & .vc-weekday {
    background-color: var(--weekday-bg);
    border-bottom: var(--weekday-border);
    border-top: var(--weekday-border);
    padding: 5px 0;
  }
  & .vc-day {
    padding: 0 5px 3px 5px;
    text-align: left;
    height: var(--day-height);
    min-width: var(--day-width);
    background-color: white;
    &.weekday-1,
    &.weekday-7 {
      background-color: #eff8ff;
    }
    &:not(.on-bottom) {
      border-bottom: var(--day-border);
      &.weekday-1 {
        border-bottom: var(--day-border-highlight);
      }
    }
    &:not(.on-right) {
      border-right: var(--day-border);
    }
  }
  & .vc-day-dots {
    margin-bottom: 5px;
  }
}
</style>
  
  <style>
.buttonActive {
  border-color: teal;
  background-color: teal;
  color: white;
  padding-top: 2px;
}
.vc-header {
  padding: 0;
}
.vc-title {
  color: grey;
  position: absolute;
  top: 0;
  left: 0;
  margin-top: -45px;
  font-weight: bold;

  font-size: 1.5rem;
  line-height: 2rem;
  z-index: 9999;
}

.vc-arrows-container {
  display: none;
}

.vc-weekday {
  text-transform: uppercase;
  color: grey;
  border-bottom: 2px solid silver;
  border-left: 1px solid silver;
  padding: 15px;
}
.vc-weeks > .vc-weekday:first-of-type {
  border-left: none;
}
.vc-weeks {
  padding: 0;
}
.vc-day {
  min-height: 5.5rem;
  max-height: 5.5rem;
  min-width: 7.2rem;
  border-bottom: 1px solid silver;
  border-left: 1px solid silver;
}
.vc-weeks > .on-left {
  border-left: none;
}
.vc-weeks > .on-bottom {
  border-bottom: none;
}
.max-h-cardM {
  max-height: 227px;
}
.step-connector {
  margin-left: 85px;
}
.min-w-16 {
  min-width: 4rem;
}
select,
input {
  outline: none;
}

.todays-sessions {
  min-height: 100%;
}

@media screen and (max-width: 1809px) {
  .second-half {
    flex-direction: column;
  }

  .calendar-container {
    min-width: 100%;
  }
  .sessions-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 15px;
    min-width: 100%;
    margin-left: 0 !important;
  }
  .add-session,
  .todays-sessions {
    min-width: 49.5%;
    max-width: 49.5%;
    margin-left: 0;
    margin-top: 0 !important;
  }

  .first-half {
    flex-direction: row;
    flex-wrap: nowrap !important;
  }
  .menu-container {
    min-width: 25%;
  }
  .notifications-container {
  }
  @media screen and (max-width: 1499px) {
    .menu-container {
      min-width: 30%;
    }
  }
  @media screen and (max-width: 1289px) {
    .menu-container {
      min-width: 40%;
    }
  }
  @media screen and (max-width: 1289px) {
    .menu-container {
      min-width: 100%;
      margin-bottom: 15px;
      justify-content: center;
      align-items: center;
    }
    .notifications-container,
    .add-session,
    .todays-sessions {
      min-width: 100%;
    }
    .first-half,
    .sessions-container {
      flex-direction: column;
    }
    .todays-sessions {
      margin-top: 15px !important;
    }
  }
}
</style>
  